/* eslint-disable react/jsx-filename-extension */
import React from "react";
import { graphql } from "gatsby";
import { mergeCraftPreview, resolveImage, resolveImages } from "@dataResolvers";
import { Home as PageTemplate } from "@pageTemplates";

export const query = graphql`
  query HomeQuery {
    craft {
      entry(section: "home") {
        title
        url
        slug
        ... on Craft_home_home_Entry {
          #meta
          metaTitle
          metaDescription
          metaImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          #hero
          heroTitle
          heroSubtitle
          heroImageMulti {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          heroCta {
            ... on Craft_heroCta_heroButtonText_BlockType {
              buttonText
              buttonlink {
                url
              }
            }
          }
          #intro
          homeGetInTouchBackgroundImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          homeGetInTouchCopy
          homeIconTextGroup {
            ... on Craft_homeIconTextGroup_iconTextBlock_BlockType {
              header
              description
              icon {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
            }
          }
          homeGetInTouchButton {
            text
            url
          }
          #cta blocks
          homeFullSteamAheadTitle
          homeFullSteamAheadDescription
          homeAheadgroup {
            ... on Craft_homeAheadgroup_homeAheadGroupBlock_BlockType {
              id
              homeAheadGroupBlockBackgroundImage {
                width
                height
                hasFocalPoint
                slug
                status
                title
                focalPoint
                url
              }
              homeAheadGroupBlockSubtitle
              homeAheadGroupBlockTitle
              homeAheadGroupBlockDescription
              homeAheadGroupBlocklink {
                url
              }
            }
          }
          #blog
          homeLatestTitle
          homeLatestDescription
        }
      }
      entries(section: "blog", limit: 3) {
        ... on Craft_blog_blog_Entry {
          id
          blogImage {
            width
            height
            hasFocalPoint
            slug
            status
            title
            focalPoint
            url
          }
          postDate
          blogCategory {
            title
          }
          title
          url
        }
      }
    }
  }
`;

const previewQuery = `query HomeQuery {
  craft {
    entry(section: "home") {
      title
      url
      slug
      ... on Craft_home_home_Entry {
        #meta
        metaTitle
        metaDescription
        metaImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        #hero
        heroTitle
        heroSubtitle
        heroImageMulti {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        heroCta {
          ... on Craft_heroCta_heroButtonText_BlockType {
            buttonText
            buttonlink {
              url
            }
          }
        }
        #intro
        homeGetInTouchBackgroundImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        homeGetInTouchCopy
        homeIconTextGroup {
          ... on Craft_homeIconTextGroup_iconTextBlock_BlockType {
            header
            description
            icon {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
          }
        }
        homeGetInTouchButton {
          text
          url
        }
        #cta blocks
        homeFullSteamAheadTitle
        homeFullSteamAheadDescription
        homeAheadgroup {
          ... on Craft_homeAheadgroup_homeAheadGroupBlock_BlockType {
            id
            homeAheadGroupBlockBackgroundImage {
              width
              height
              hasFocalPoint
              slug
              status
              title
              focalPoint
              url
            }
            homeAheadGroupBlockSubtitle
            homeAheadGroupBlockTitle
            homeAheadGroupBlockDescription
            homeAheadGroupBlocklink {
              url
            }
          }
        }
        #blog
        homeLatestTitle
        homeLatestDescription
      }
    }
    entries(section: "blog", limit: 3) {
      ... on Craft_blog_blog_Entry {
        id
        blogImage {
          width
          height
          hasFocalPoint
          slug
          status
          title
          focalPoint
          url
        }
        postDate
        blogCategory {
          title
        }
        title
        url
      }
    }
  }
}`;

const dataResolver = ({ craft }) => {
  const {
    title,
    url,
    slug,
    // meta
    metaTitle,
    metaDescription,
    metaImage,
    // hero
    heroTitle,
    heroSubtitle,
    heroImageMulti,
    heroCta,
    // intro
    homeGetInTouchBackgroundImage,
    homeGetInTouchCopy,
    homeIconTextGroup,
    homeGetInTouchButton,
    // cta heading
    homeFullSteamAheadTitle,
    homeFullSteamAheadDescription,
    // ctas
    homeAheadgroup,
    // blog
    homeLatestTitle,
    homeLatestDescription,
  } = craft.entry;

  const resolveFeatures = features =>
    features.map(f => {
      return {
        title: f.header,
        copy: f.description,
        icon: resolveImage(f.icon),
      };
    });
  const resolveCategories = categories =>
    categories.map(c => {
      return c.title;
    });
  const articles = craft.entries.map(a => {
    return {
      id: a.id,
      image: resolveImage(a.blogImage),
      date: a.postDate.split("T")[0],
      categories: resolveCategories(a.blogCategory).join(", "),
      title: a.title,
      url: a.url,
    };
  });
  const blocks = homeAheadgroup.map(block => {
    return {
      image: resolveImage(block.homeAheadGroupBlockBackgroundImage),
      subtitle: block.homeAheadGroupBlockSubtitle,
      title: block.homeAheadGroupBlockTitle,
      description: block.homeAheadGroupBlockDescription,
      url: block.homeAheadGroupBlocklink[0].url,
    };
  });
  return {
    title,
    hero: {
      heading: heroTitle,
      subhead: heroSubtitle,
      images: resolveImages(heroImageMulti),
      button: {
        text: heroCta[0].buttonText,
        url: heroCta[0].buttonlink[0].url,
      },
    },
    intro: {
      image: resolveImage(homeGetInTouchBackgroundImage),
      copy: homeGetInTouchCopy,
      features: resolveFeatures(homeIconTextGroup),
      button: {
        text: homeGetInTouchButton.text,
        url: homeGetInTouchButton.url,
      },
    },
    ctas: {
      heading: homeFullSteamAheadTitle,
      copy: homeFullSteamAheadDescription,
      blocks,
    },
    blog: {
      heading: homeLatestTitle,
      copy: homeLatestDescription,
      articles,
    },
    meta: {
      title,
      url,
      slug,
      metaTitle,
      metaDescription,
      metaImage: resolveImage(metaImage),
    },
  };
};

const QueryContainer = ({ data, pageContext }) => {
  const { slug: _slug } = pageContext;
  const merged = mergeCraftPreview(data, previewQuery, {
    slug: _slug,
    site: process.env.GATSBY_CRAFT_SITE_HANDLE,
  });

  // When previewing unpublished pages, merged will be empty until
  // the preview reqeust has finished, so returning null to avoid
  // component errors about missing content.
  if (!merged) {
    return null;
  }

  const resolvedData = dataResolver(merged);

  return <PageTemplate data={resolvedData} />;
};

export default QueryContainer;
